import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
/* import Img from "gatsby-image" */
import { graphql }  from 'gatsby'
import Cart from '../images/cart.svg'
import TV from '../images/display.svg'
import Settings from '../images/configuration.svg'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Div from '../components/Div/Div.styles'
import H4 from '../components/H4'
import Paragraph from '../components/Paragraph'
import CPanel from '../images/licenses/cpanel.png'
import CloudLinux from '../images/licenses/cloudlinux.png'
import FantasticoDeluxe from '../images/licenses/fantastico-deluxe.png'
import Imunufy from '../images/licenses/imunufy.png'
import kernelCare from '../images/licenses/kernel-care.png'
import Rvskin from '../images/licenses/rvskin.png'
import Softaculous from '../images/licenses/softaculous.png'
import WhmExtra from '../images/licenses/whm-extra.png'
import WHMSonic from '../images/licenses/whm-sonic.png'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledLicense,
  header,
  licenses,
  licenseItem,
  licenseHeader,
  circleItem,
  licenseAction
} from '../components/styles/License.styles'

const LicenseActions = ({
  onBuy,
  onDisplay,
  onConfig,
  buyLink,
  displayLink,
  configLink,
}) => {
  return (
    <>
      <a
        href={buyLink}
      >
        <Div
          css={circleItem}
          onClick={onBuy}
          style={{
            color: '#ff5800'
          }}
        >
          <img
            src={Cart} 
          />
        </Div>
      </a>
      <a
        href={displayLink}
      >
        <Div 
          css={circleItem}
          onClick={onDisplay}
          style={{
            color: '#ff5800',
            marginLeft: 24,
            marginRight: 24
          }}
        >
          <img
            src={TV} 
          />
        </Div>
      </a>
      <a
        href={configLink}
      >
        <Div 
          css={circleItem}
          onClick={onConfig}
          style={{
            color: '#ff5800'
          }}
        >
          <img
            src={Settings} 
          />
        </Div>
      </a>
    </>
  )
}

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <Fragment>
      <section css={styledLicense}>
        <Container>
          <Row
            flexDirection={['column', 'row']}
            css={header}
          >
            <Column
              width={[1]}
            >
              <H4>
                <span>licenças</span><br />
                Ofertas exclusivas para clientes da HostDime Brasil
              </H4>
              <Paragraph>Através de nossas fortes parcerias com nossos fornecedores de softwares, somos capazes de oferecer licenças com descontos em produtos de terceiros. Estas ofertas são exclusivas para clientes da HostDime Brasil.</Paragraph>
            </Column>
          </Row>
        </Container>
      </section>
      <section css={licenses}>
        <Container>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row', 'row']}
            alignItems={'center'}
          >
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={CPanel} />
                  </Div>
                  <Div>
                    <Paragraph>Através de nossa parceria com o cPanel, oferecemos o software líder de controle e administração de hospedagens.</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/cpanel/add'}
                      displayLink={'https://cpanel.net/'}
                      configLink={'https://documentation.cpanel.net/display/76Docs/Installation+Guide'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={FantasticoDeluxe} />
                  </Div>
                  <Div>
                    <Paragraph>O Fantastico é o mais conhecido e o mais estável software de instalação de aplicações, além de ter sido o primeiro a surgir do seu grupo. Usado para instalar milhões de instâncias dos principais softwares por milhões de usuários.</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/fantastico/add'}
                      displayLink={'https://netenberg.com/fantastico.php'}
                      configLink={'https://netenberg.com/fantastico-installation.html'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={WHMSonic} />
                  </Div>
                  <Div>
                    <Paragraph>WHMSonic é um plugin desenvolvido para o cPanel que permite a você e a seus clientes gerenciarem serviços de streaming diretamente do cPanel.</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/whmsonic/add'}
                      displayLink={'http://whmsonic.com/'}
                      configLink={'http://www.whmsonic.com/help/index.php/article/how-to-install-whmsonic'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row', 'row']}
            alignItems={'center'}
          >
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={WhmExtra} />
                  </Div>
                  <Div>
                    <Paragraph>O WHMXtra instala firewalls, proteção DDOS, FFMPEG e muitas mais. Tudo em WHMXtra é projetado para poupar tempo e dinheiro quando se trata em proteger, reparar e gerenciar o seu servidor!</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/whmxtra/add'}
                      displayLink={'http://whmsonic.com/'}
                      configLink={'https://www.buycpanel.com/knowledgebase/whmxtra-install-instructions-whmxtra-troubleshooting/'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={Rvskin} />
                  </Div>
                  <Div>
                    <Paragraph>RVSkin é um gerenciador de temas em várias línguas adaptado especificamente para servidores cPanel. Use RVSkin para divulgar sua empresa e ajudar o seu negócio a se destacar no mercado.</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/rvskin/add'}
                      displayLink={'https://www.rvskin.com/'}
                      configLink={'https://www.rvskin.com/installation'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={Softaculous} />
                  </Div>
                  <Div>
                    <Paragraph>O Softaculous é um novo instalador automático com mais de 140 softwares que está sendo sempre atualizado com novos scripts. Estes scripts suprem a grande maioria das necessidades que seus clientes venham a ter.</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/softaculous/add'}
                      displayLink={'http://www.softaculous.com/'}
                      configLink={'http://www.softaculous.com/docs/enduser/install/'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
          </Row>
          <Row
            justifyContent={'center'}
            flexDirection={['column', 'column', 'row', 'row']}
            alignItems={'center'}
          >
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={Imunufy} />
                  </Div>
                  <Div>
                    <Paragraph>O Imunify360 é um sistema de segurança muito sofisticado, que coleta e processa constantemente um grande número de informações sobre novos ataques em servidores ao redor do mundo.</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/imunify360/add'}
                      displayLink={'https://www.imunify360.com/'}
                      configLink={'https://www.imunify360.com/getting-started/13-installation-and-settings/44-installation'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={CloudLinux} />
                  </Div>
                  <Div>
                    <Paragraph>CloudLinux mantém seus servidores estáveis e seus clientes satisfeitos, mesmo sob extremo estresse. Isto ocorre porque cada conta é isolada em seu ambiente virtualizado (LVE) com recursos pré-configurados por você.</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/cloudlinux/add'}
                      displayLink={'https://www.cloudlinux.com/'}
                      configLink={'https://docs.cloudlinux.com/cloudlinux_installation/#installation'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
            <Column>
              <Div
                maxWidth={['100%', 328]}
              >
                <Div
                  css={licenseItem}
                >
                  <Div css={licenseHeader}>
                    <img src={kernelCare} />
                  </Div>
                  <Div>
                    <Paragraph>KernelCare é um serviço que fornece atualizações importantes para o seu kernel em execução, eliminando a necessidade de reiniciar o servidor.</Paragraph>
                  </Div>
                  <Div css={licenseAction}>
                    <LicenseActions 
                      buyLink={'https://core.hostdime.com.br/licenses/kernelcare/add'}
                      displayLink={'https://www.kernelcare.com/'}
                      configLink={'https://cloudlinux.com/getting-started-with-cloudlinux-os'}
                    />
                  </Div>
                </Div>
              </Div>
            </Column>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Licenças' />
      <PageTemplate
        title='Licenças'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage