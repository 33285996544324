import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const styledLicenseBps = css(
  mq({
    marginTop: [49.5, 50, 47, 47],
    marginBottom: [40]
  })
)

export const styledLicense = css`
  ${styledLicenseBps};
`

/* HEADER */
export const H4HeaderBps = css(
  mq({
    maxWidth: [328, '100%', 600, 600],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    marginBottom: [16],
    letterSpacing: [0.25],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const PHeaderBps = css(
  mq({
    maxWidth: [328, '100%', 680, 680],
    marginLeft: [0, 0, 200, 302],
    marginRight: [0, 0, 200, 302],
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const spanBps = css(
  mq({
    textAlign: ['left', 'left', 'center', 'center']
  })
)

export const licensesBps = css(
  mq({
    paddingTop: [50],
    paddingBottom: [34, 34, 54, 54]
  })
)

export const licenseItemBps = css(
  mq({
    marginBottom: [16, 16, 24, 24]
  })
)

export const licenseItems = css(
  mq({
    display: ['flex'],
    margin: ['0 auto'],
    justifyContent: ['center']
  })
)

export const header = css`
  display: flex;
  text-align: center;
  span {
    ${spanBps};
    text-transform: uppercase;
    font-size: 11.8px;
    font-weight: 600;
    line-height: 1.36;
    letter-spacing: 2px;
    color: rgba(0, 0, 0, 0.6);
  }
  h4 {
    ${H4HeaderBps};
    color: rgba(0, 0, 0, 0.87);
  }
  p {
    ${PHeaderBps};
  }
`

export const licenses = css`
  ${licensesBps};
  background-color: #f3f5f5;
`

export const licenseItem = css`
  ${licenseItemBps};
  width: 328px;
  height: 377px;
  border-radius: 18px;
  background-color: #ffffff;
  p {
    width: 296px;
    height: 144px;
    padding: 15.5px 16px 24px 16px;
    text-align: center;
    align-items: center;
    display: flex;
  }
`

export const licenseHeader = css`
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid #f3f5f5;
  img {
    width: 126px;
    height: 66px;
    object-fit: contain;
  }
`

export const circleItem = css`
  width: 70px;
  height: 70px;
  background-color: #fff5ef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const licenseAction = css`
  display: flex;
  flex-direction: row;
  padding: 0 30px;
  div:nth-child(2) {
    margin-left: 28px;
    margin-right: 28px;
  }
`